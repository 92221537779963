import React from 'react';
import Header from '../components/Samokat/Header';
import Hero from '../components/Samokat/Hero';
import Specs from '../components/Samokat/Specs';
import Promo from '../components/Samokat/Promo';
import Shop from '../components/Samokat/Shop';
import EasyToUse from '../components/Samokat/EasyToUse';
import EvaBreeze from '../components/Samokat/EvaBreeze';
import Features from '../components/Samokat/Features';
import LiveWithComfort from '../components/Samokat/LiveWithComfort';
import Footer from '../components/Samokat/Footer';
import Popup from '../components/Samokat/Popup';
import ScrollEvaBreezeContext from '../components/Samokat/contexts';

// const handleEvaBreezeTranslateY = (touchUpY, touchDownY) => {
// const windowScrollY = window.scrollY
// const deltaY =  window.innerHeight / 2
// const translateYDistance = touchDownY - touchUpY
// }

export default function Samokat() {
  const [evaBreezePositionData, setEvaBreezePositionData] = React.useState({
    translateY: 'none',
  });

  const scrollEvaBreezeContextValue = React.useMemo(
    () => ({
      evaBreezePositionData,
      setEvaBreezePositionData,
    }),
    [evaBreezePositionData, setEvaBreezePositionData]
  );

  // window.addEventListener('scroll', handleEvaBreezeTranslateY)

  return (
    <>
      <Header />
      <Hero />
      <ScrollEvaBreezeContext.Provider value={scrollEvaBreezeContextValue}>
        <Promo />
        <Specs />
      </ScrollEvaBreezeContext.Provider>
      <Shop />
      <EasyToUse />
      <EvaBreeze />
      <Features />
      <LiveWithComfort />
      <Popup />
      <Footer />
    </>
  );
}
