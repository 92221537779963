/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';
import Anchor from '../Anchor';
import { media } from '../../../ui/common';

export const Button = styled(Anchor)`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  white-space: nowrap;
  max-width: 100vw;
  padding: 16px 16px;
  span {
    margin-left: 14px;
  }
  ${media.phone`
    padding: 16px 32px;
  `}
`;
export const Wrapper = styled.div`
  position: sticky;
  bottom: 0;
  padding: 22px;
  z-index: 100;
  display: flex;
  justify-content: center;

  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(54px);

  /* prettier-ignore */
  @supports not ((backdrop-filter: none)) {
    background: #232323;
  }

  @media (min-width: 870px) {
    display: none;
  }
`;
