import styled from 'styled-components';
import { media } from '../../../ui/common';
import Anchor from '../Anchor';

import Blob from '../Blob';

export const Section = styled.section`
  overflow: hidden;
  ${media.medium`
    margin-top: -124px;
  `}
`;

export const Body = styled.section`
  background: #84b8d0;
  color: #fff;
  overflow: hidden;
  margin: -1px 0; // Fix white lines on top and bottom
  ${media.phone`
    padding-bottom: 288px;
  `}
  ${media.medium`
    padding-top: 64px;
    padding-bottom: 48px;
  `}
  ${media.laptop`
    padding-top: 128px;
    padding-bottom: 76px;
  `}
`;

export const Row = styled.div`
  ${media.phone`
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  `}
  ${media.medium`
    justify-content: flex-end;
  `}
`;

export const Content = styled.div`
  margin-top: 10px;
  ${media.phone`
    margin-top: 0;
    max-width: 340px;
  `}
  ${media.medium`
    max-width: 600px;
  `}
`;

export const Heading = styled.h2`
  font-family: 'Craftwork Grotesk';
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 16px;
  ${media.phone`
    font-size: 30px;
    letter-spacing: 0.025rem;
  `}
  ${media.medium`
    font-size: 42px;
    letter-spacing: normal;
  `}
`;
export const Text = styled.p`
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.24;
  margin: 0 0 24px;
  ${media.phone`
    margin-bottom: 30px;
  `}
  ${media.medium`
    margin-bottom: 24px;
  `}
`;

export const ImageWrap = styled.div`
  width: 246px;
  margin-left: auto;
  margin-right: 16px;
  position: relative;
  ${media.phone`
    transform: translate(-48px,160px) scale(1.77);
  `}
  ${media.medium`
    transform: translate(-46px, 16px);
    width: 380px;
    margin: 0
  `}
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  position: relative;
`;

export const DeviceBlob = styled(Blob)`
  position: absolute;
  width: auto;
  height: 100%;
  transform: translate(48px, -10px);
  ${media.phone`
    height: 120%;
    transform: translate(48px, -30px);
  `}
  ${media.medium`
    transform: translate(126px, -80px);
  `}
`;

export const BlobGroup = styled.div`
  position: absolute;
  ${media.phone`
    top: 100%;
    transform: translate(-45px,100px);
  `}
  ${media.medium`
    transform: translate(-130px,-220px);
  `}
`;
export const TinyBlob = styled(Blob)`
  height: 32px;
  display: block;
`;
export const SmallBlob = styled(Blob)`
  height: 110px;
  transform: translate(-2px, -16px);
`;
export const AsideBlob = styled(Blob)`
  height: 68px;
  transform: translate(58px, 30px);
  ${media.phone`
    transform: translate(72px,2px);
  `}
`;
export const Button = styled(Anchor)`
  display: inline-block;
  box-shadow: none;
`;
