import hammer from '../../../images/samokat/icon_hammer.svg';
import snow from '../../../images/samokat/icon_snow.svg';
import battery from '../../../images/samokat/icon_battery.svg';
import volume from '../../../images/samokat/icon_volume.svg';
import water from '../../../images/samokat/icon_water.svg';
import size from '../../../images/samokat/icon_size.svg';

export default [
  {
    icon: hammer,
    title: 'Не требует установки',
    key: 0,
  },
  {
    icon: snow,
    title: 'Охлаждает на 5-14°С',
    key: 1,
  },
  {
    icon: battery,
    title: 'Потребляет всего 7-12W',
    key: 2,
  },
  {
    icon: volume,
    title: 'Тихий',
    key: 3,
  },
  {
    icon: water,
    title: 'Не сушит воздух',
    key: 4,
  },
  {
    icon: size,
    title: 'Компактный',
    key: 5,
  },
];
