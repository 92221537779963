// Based on https://blobanimation.com/
import React from 'react';
import PropTypes from 'prop-types';
import { animate0 } from './animate';

export default function Blob({ className, animate = animate0 }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 500"
      color="white"
      className={className}
    >
      <path fill="currentColor" style={{ opacity: '1' }}>
        <animate
          attributeName="d"
          dur="10s"
          repeatCount="indefinite"
          values={animate}
        />
      </path>
    </svg>
  );
}

Blob.propTypes = {
  className: PropTypes.string,
  animate: PropTypes.string,
};

Blob.defaultProps = {
  className: undefined,
  animate: animate0,
};
