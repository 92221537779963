import React from 'react';
import { PropTypes } from 'prop-types';

export default function HeartsIcon({ width, height }) {
  return (
    <div width={width || 'inherit'} height={height || 'inherit'}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 248 230"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M229.936 51.5135C290.587 80.6956 88.7448 193.031 88.7448 193.031C88.7448 193.031 94.6766 -5.76898 150.612 31.9133C181.284 52.5762 140.279 104.001 140.279 104.001C140.279 104.001 196.837 35.5882 229.936 51.5135Z"
          fill="#FF2C2C"
        />
        <path
          d="M64.437 31.9039C105.572 15.9003 69.0559 162.918 69.0559 162.918C69.0559 162.918 -23.5626 71.0842 19.5354 61.1455C43.1679 55.6957 49.4575 98.3673 49.4575 98.3673C49.4575 98.3673 41.9887 40.6374 64.437 31.9039Z"
          fill="#FF2C2C"
        />
      </svg>
    </div>
  );
}

HeartsIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

HeartsIcon.defaultProps = {
  width: 248,
  height: 230,
};
