import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StaticImage } from 'gatsby-plugin-image';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

import { colors } from '../../../ui/common';
import LocalizedLink from '../../localized-link';
import MainBasket from '../../Basket';
import {
  StyledAppBar,
  StyledToolbar,
  StyledLink,
  StyledLogo,
  Delimiter,
  LinksWrapper,
  BigImageWrapper,
  SmallImageWrapper,
  BasketButton,
  RightSide,
  StyledAnchor,
} from './styled';
import NavContext from '../../Navbar/context';
import ActionType from '../../../store/action-type';
import { BuyButtonDiscount, BuyButtonDiscountText } from '../Shop/styled';

function BasketIcon() {
  return (
    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 18a1 1 0 0 0 1-1v-2a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1Zm-4 0a1 1 0 0 0 1-1v-2a1 1 0 0 0-2 0v2a1 1 0 0 0 1 1Zm9-12h-1.38l-1.73-3.45a1 1 0 1 0-1.78.9L15.38 6H8.62l1.27-2.55a1 1 0 0 0-1.78-.9L6.38 6H5a3 3 0 0 0-.92 5.84l.74 7.46a3 3 0 0 0 3 2.7h8.38a3 3 0 0 0 3-2.7l.74-7.46A3 3 0 0 0 19 6Zm-1.81 13.1a1 1 0 0 1-1 .9H7.81a1 1 0 0 1-1-.9L6.1 12h11.8l-.71 7.1ZM19 10H5a1 1 0 1 1 0-2h14a1 1 0 1 1 0 2Z" />
    </svg>
  );
}

function HeaderLinks() {
  return (
    <LinksWrapper>
      <StyledLink href="https://samokat.ru/">
        <BigImageWrapper>
          <StaticImage
            alt="Samokat"
            layout="fixed"
            width={134}
            src="../../../images/samokat/samokat-logo.png"
            placeholder="none"
            quality={80}
          />
        </BigImageWrapper>
        <SmallImageWrapper>
          <StaticImage
            alt="Samokat"
            layout="fixed"
            width={94}
            src="../../../images/samokat/samokat-logo.png"
            placeholder="none"
            quality={80}
          />
        </SmallImageWrapper>
      </StyledLink>

      <Delimiter />

      <LocalizedLink to="/">
        <StyledLogo textColor={colors.color15} />
      </LocalizedLink>
    </LinksWrapper>
  );
}

export default function Header() {
  const trigger = useScrollTrigger();
  const dispatch = useDispatch();
  const { basket } = useSelector((state) => ({
    basket: state.basket.list,
  }));
  const openBasket = () => {
    dispatch({ type: ActionType.Basket.Visible, payload: true });
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <NavContext.Provider value={{ basket, openBasket }}>
      <Slide appear={false} direction="down" in={!trigger}>
        <StyledAppBar>
          <StyledToolbar>
            <HeaderLinks />

            <RightSide>
              <StyledAnchor>
                КУПИТЬ С ПРОМОКОДОМ
                <BuyButtonDiscount>
                  <StaticImage
                    alt="fire"
                    layout="fixed"
                    width={18}
                    src="../../../images/samokat/fire.png"
                    placeholder="none"
                    quality={95}
                  />
                  <BuyButtonDiscountText>20%</BuyButtonDiscountText>
                </BuyButtonDiscount>
              </StyledAnchor>
              <BasketButton type="button" onClick={openBasket}>
                <BasketIcon />
              </BasketButton>
            </RightSide>
          </StyledToolbar>
        </StyledAppBar>
      </Slide>
      <MainBasket checkout />
    </NavContext.Provider>
  );
}
