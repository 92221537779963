import React from 'react';
import PropTypes from 'prop-types';

import { colorsBar } from '../../../../ui/common';
import {
  StyledForm,
  Wrapper,
  ItemWrapper,
  StyledInput,
  Choice,
  SrOnlySpan,
} from './styled';

export default function ColorPicker({
  prefix,
  colorList,
  activeColor,
  onChange,
}) {
  return (
    <StyledForm>
      <Wrapper>
        {colorList.map((color) => (
          <ItemWrapper key={color}>
            <StyledInput
              id={`${prefix}-${color}`}
              type="radio"
              value={color}
              checked={activeColor === color}
              onChange={(event) => {
                onChange(event.target.value);
              }}
            />
            <label htmlFor={`${prefix}-${color}`}>
              <Choice $background={colorsBar[color]}>
                {activeColor === color && (
                  <svg
                    width="15"
                    height="11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.49.51a1.04 1.04 0 0 0-1.48 0L5.25 8.282l-3.26-3.27A1.064 1.064 0 1 0 .51 6.54l4 4a1.042 1.042 0 0 0 1.48 0l8.5-8.5a1.042 1.042 0 0 0 0-1.53Z"
                      fill="#000"
                    />
                  </svg>
                )}
              </Choice>
              <SrOnlySpan>{color}</SrOnlySpan>
            </label>
          </ItemWrapper>
        ))}
      </Wrapper>
    </StyledForm>
  );
}
ColorPicker.propTypes = {
  prefix: PropTypes.string.isRequired,
  colorList: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  activeColor: PropTypes.string.isRequired,

  onChange: PropTypes.func.isRequired,
};
