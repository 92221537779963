import styled from 'styled-components';

export const Section = styled.section`
  padding-top: 140px;
  overflow-x: hidden;
`;

export const Title = styled.h2`
  background: #84b8d0;
  color: #ffffff;
  font-stretch: 748;
  text-align: center;
  border-radius: 24px;
  transform: rotate(-10.19deg);
  font-family: 'Craftwork Grotesk';
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  margin: 0 auto;
  padding: 21px 34px;
  max-width: 325px;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  top: -50px;
  left: -30px;

  @media (min-width: 768px) {
    font-weight: 400;
    font-size: 30px;
    line-height: 120%;
    max-width: 391px;
  }

  @media (min-width: 1440px) {
    font-weight: 700;
    font-size: 42px;
    line-height: 120%;
    max-width: 512px;
    left: -70px;
  }
`;

export const ImgBox = styled.div`
  position: relative;
  margin-bottom: 38px;
  padding: 0 16px;
  z-index: 2;
  margin: 0 auto;
  max-width: 422px;
  transform: translateY(100px);

  @media (min-width: 1440px) {
    max-width: 704px;
    transform: translateY(150px);
  }
`;

export const Img = styled.img`
  width: 100%;
  border-radius: 32px;
`;

export const HeartsIconWrap = styled.div`
  width: 150px;
  height: 137px;
  position: absolute;
  right: -60px;
  top: -35px;

  @media (min-width: 768px) {
    width: 171px;
    height: 158px;
    right: -20px;
  }

  @media (min-width: 1440px) {
    width: 248px;
    height: 230px;
    right: -30px;
    top: -70px;
  }
`;

export const WaveWrapper = styled.div`
  width: 1700vw;
  height: 150px;

  @media (min-width: 768px) {
    width: 700vw;
    height: 200px;
  }
`;

export const Footer = styled.footer`
  padding-bottom: 32px;
  padding: 22px 16px 32px;
  background-color: #84b8d0;
  // fix empty line between wave and Footer
  margin-top: -2px;

  @media (min-width: 768px) {
    padding: 0 16px;
  }
`;

export const FooterContainer = styled.div`
  margin: 0 auto;
  max-width: 422px;
  width: 100%;
  padding-bottom: 10px;

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 5px;
    padding-bottom: 0;
  }

  @media (min-width: 1440px) {
    max-width: 600px;
    align-items: center;
  }

  p {
    font-family: 'HelveticaNeueCyr', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 124%;
    color: #ffffff;
    margin: 0 auto 16px;

    @media (min-width: 768px) {
      margin: 0 auto;
      font-weight: 400;
      font-size: 20px;
      line-height: 124%;
    }
  }

  a {
    background: #ffffff;
    box-shadow: 0px 12px 30px rgba(255, 255, 255, 0.25);
    border-radius: 13px;
    padding: 16px 32px;
    color: #3e3e3e;
    font-weight: 700;
    font-size: 16px;
    line-height: 124%;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
  }
`;
