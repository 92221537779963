import styled from 'styled-components';

export const StyledForm = styled.form`
  align-self: flex-end;
  position: relative;
`;

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 14px;
`;

export const ItemWrapper = styled.div`
  height: 35px;
  margin-top: 6px;
`;

// Based on https://codepen.io/manabox/pen/raQmpL
export const StyledInput = styled.input`
  position: absolute;
  left: -9999px;
`;

// Based on https://webaim.org/techniques/css/invisiblecontent/
export const SrOnlySpan = styled.span`
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`;

export const Choice = styled.div`
  width: 25px;
  height: 25px;
  background-color: ${(props) => props.$background};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::after {
    position: absolute;
    content: '';
    box-sizing: border-box;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid rgba(0, 0, 0, 0.3);
  }
`;
