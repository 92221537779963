import styled from 'styled-components';

export const Wrapper = styled.section`
  padding: 0 16px;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background: #84b8d0;
  border-radius: 32px;
  box-sizing: border-box;
  margin: 0 12px;
  height: 100%;

  transition: all 200ms ease;
  transition-property: box-shadow, transform;
`;
export const ProductCardWrapper = styled(CardWrapper)`
  padding: 42px 20px 24px;
`;
export const NotesCardWrapper = styled(CardWrapper)`
  padding: 16px;
`;

export const CardListWrapper = styled.div`
  margin: 0 auto;
  max-width: 1164px;

  & .swiper-slide {
    height: auto;
    margin: 12px 0;
  }

  & .swiper-slide:hover {
    & ${CardWrapper} {
      box-shadow: 0px 10px 18px -3px rgba(0, 0, 0, 0.25);
      transform: translateY(-12px);
    }
  }
`;

export const Heading = styled.p`
  margin: 0 0 16px 0;
  font-family: 'Craftwork Grotesk';
  font-weight: 700;
  font-size: 42px;
  line-height: 120%;
  color: #fff;
`;

export const Info = styled.p`
  margin: 0 0 16px 0;
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
`;

export const PriceTitle = styled.p`
  margin: 24px 0 4px;
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 124%;
  text-align: center;
  color: #fff;
  align-self: flex-start;
`;

export const PriceWrapper = styled.div`
  position: relative;
  align-self: stretch;
  display: flex;
  margin: 0 0 12px 0;
  justify-content: space-between;
  align-items: center;
`;

export const Price = styled.p`
  margin: 0;
  font-family: 'Craftwork Grotesk';
  font-weight: 700;
  font-size: 42px;
  line-height: 120%;
  text-align: center;
  color: #fff;
  align-self: flex-start;
`;

export const BuyButtonText = styled.span`
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 124%;
  color: #3e3e3e;
`;
export const BuyButtonDiscount = styled.span`
  display: flex;
  align-items: center;
  border: 1px solid #ff0000;
  border-radius: 8px;
  padding: 4px;
`;
export const BuyButtonDiscountText = styled.span`
  margin-left: 4px;
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 124%;
  color: #ff3838;
`;
export const StyledBuyButton = styled.button`
  cursor: pointer;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  background: #fff;
  box-shadow: 0px 12px 30px rgba(255, 255, 255, 0.25);
  border: 0;
  border-radius: 13px;

  &:hover {
    transition: outline 200ms ease;
    outline: 1px solid #ff0000;

    ${BuyButtonText} {
      color: #ff3838;
      transition: color 200ms ease;
    }
  }
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  bottom: 60px;
  padding: 10px 16px;
  background: #7ec722;
  box-shadow: 0 2px 4px rgba(30, 40, 67, 0.15);
  border-radius: 11px;
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #fff;
  transition: 0.25s ease-in-out;
  visibility: hidden;
  opacity: 0;
`;

export const TooltipList = styled.ol`
  padding-left: 16px;
`;

export const FullImg = styled.img`
  width: 250px;
  height: 250px;
  margin: -46px 0 -30px;
`;

export const SpecsTitle = styled.p`
  margin: 48px 0 0 32px;
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-weight: 550;
  font-size: 20px;
  line-height: 20px;
  color: #000;
`;

export const SpecsList = styled.ul`
  margin: 0;
  padding: 0 24px;
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 124%;
  color: #fff;
`;

export const StyledInfoButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 52px;
  height: 52px;
  background-color: #7ec722;
  box-shadow: 0px 12px 30px rgba(126, 199, 34, 0.25);
  border-radius: 13px;
  border: 0;

  &:hover {
    background-color: transparent;
    border: 2px solid #7ec722;
    transition: all 200ms ease;
    transition-property: border, background-color;

    & > svg path {
      fill: #7ec722;
      transition: fill 200ms ease;
    }

    & + ${TooltipWrapper} {
      visibility: visible;
      opacity: 1;
    }
  }
`;
