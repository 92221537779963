import product1 from '../../../images/samokat/samokat_product_1.png';
import product2 from '../../../images/samokat/samokat_product_2.png';
import product3 from '../../../images/samokat/samokat_product_3.png';
import product4 from '../../../images/samokat/samokat_product_4.png';
import { animate1, animate2, animate3, animate4 } from '../Blob/animate';

export default [
  {
    id: 1,
    icon: product1,
    title: 'Наполни бак водой',
    animate: animate1,
    coordinates: {
      x: 220,
      y: 440,
    },
  },
  {
    id: 2,
    icon: product2,
    title: 'Подключи к&nbsp;источнику питания через USB',
    animate: animate2,
    coordinates: {
      x: 200,
      y: 380,
    },
  },
  {
    id: 3,
    icon: product3,
    title: `<p>Нажми на кнопку включения.</p>
      <p>Удерживай кнопку для включения подсветки.</p>`,
    animate: animate3,
    coordinates: {
      x: 160,
      y: 400,
    },
  },
  {
    id: 4,
    icon: product4,
    title: 'Отрегулируй направление воздушного потока. Наслаждайся!',
    animate: animate4,
    coordinates: {
      x: 190,
      y: 380,
    },
  },
];
