import styled from 'styled-components';
import Link from '@material-ui/core/Link';

export const Footer = styled.footer`
  background: #84b8d0;
  color: #fff;
  padding: 50px 16px 21px;

  font-family: 'HelveticaNeueCyr', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 124%;

  @media (min-width: 1440px) {
    padding-top: 148px;
  }
`;

export const FooterContainer = styled.div`
  max-width: 568px;
  margin: 0 auto;

  @media (min-width: 1440px) {
    max-width: 1100px;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
  }
`;

export const LinkBox = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 4px;
  display: block;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Title = styled.h3`
  color: #fff;
  margin: 0 0 16px;
  font-family: 'Craftwork Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;

  @media (min-width: 768px) {
    font-weight: 700;
    font-size: 42px;
    line-height: 120%;
  }
`;

export const Copyright = styled.p`
  padding-top: 32px;
  margin: 0;
  font-weight: 550;
  font-size: 20px;
  line-height: 124%;
  text-align: center;

  @media (min-width: 768px) {
    padding-top: 42px;
  }

  @media (min-width: 1440px) {
    padding-top: 56px;
  }
`;

export const CompanyInfo = styled.p`
  margin-bottom: 16px;
`;

export const SubscribeBox = styled.div`
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 24px;
  margin-bottom: 32px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    max-width: 568px;
    margin: 0 auto 54px;
  }

  @media (min-width: 1440px) {
    max-width: 558px;
    padding: 30px;
    margin-right: 0;
  }

  * {
    box-sizing: border-box;
  }
`;

export const SubscribeBoxTitle = styled.h3`
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 124%;
  text-align: center;
  color: #000000;
  margin-top: 0;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin-bottom: 14px;
  }
`;

export const SubscribeBoxForm = styled.div`
  margin-bottom: 12px;
  position: relative;
`;

export const SubscribeBoxInput = styled.input`
  background: #ffffff;
  border: 2px solid #e4e4e4;
  border-radius: 35px;
  color: #b4b4b4;
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-style: italic;
  font-weight: 550;
  font-size: 16px;
  line-height: 124%;
  width: 100%;
  padding: 14px 23px;
  margin-bottom: 12px;

  @media (min-width: 768px) {
    padding-right: 100px;
  }

  &::placeholder {
    color: #b4b4b4;
  }
`;

export const SubscribeBoxSubmitButton = styled.button`
  background: #7ec722;
  border-radius: 32px;
  padding: 13px 25px;
  color: #fff;
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 124%;
  border: none;
  margin: 0 auto;
  display: block;

  @media (min-width: 768px) {
    position: absolute;
    right: 2px;
    top: 3px;
  }
`;

export const SubscribeBoxPrivacyText = styled.p`
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 124%;
  text-align: center;
  color: #656565;
  margin: 0;
`;

export const SubscribeBoxPrivacyLink = styled(Link)`
  color: #656565;
  text-decoration: underline;
`;
