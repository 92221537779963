import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Content } from './styled';

export default function Wave({
  speed,
  height,
  heightInherit,
  widthInehrit,
  opacity,
  width,
  isInverted,
}) {
  return (
    <Wrapper
      height={height}
      heightInherit={heightInherit}
      widthInehrit={widthInehrit}
      $isInverted={isInverted}
    >
      <Content
        $speed={speed}
        height={height}
        $opacity={opacity}
        width={width || 700}
        heightInherit={heightInherit}
        widthInehrit={widthInehrit}
      />
    </Wrapper>
  );
}

Wave.propTypes = {
  speed: PropTypes.number,
  height: PropTypes.number,
  opacity: PropTypes.number,
  width: PropTypes.number,
  isInverted: PropTypes.bool,
  widthInehrit: PropTypes.bool,
  heightInherit: PropTypes.bool,
};

Wave.defaultProps = {
  speed: 42,
  height: 12,
  opacity: 1,
  width: 700,
  isInverted: false,
  heightInherit: false,
  widthInehrit: false,
};
