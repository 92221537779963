import React, { useState } from 'react';
import axios from 'axios';
import {
  SubscribeBox,
  SubscribeBoxTitle,
  SubscribeBoxForm,
  SubscribeBoxInput,
  SubscribeBoxSubmitButton,
  SubscribeBoxPrivacyText,
  SubscribeBoxPrivacyLink,
} from './styled';
import Spinner from '../../../ui/spinner';

export default function Subscribe() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ anim: false, text: '' });

  const onSubmitEmail = async (v) => {
    /* show anim */
    setStatus({ anim: true, text: '' });

    // request to the server.
    try {
      const res = await axios.post(`/api/subscribeUser`, { email: v });

      if (res.status === 200)
        setStatus({ anim: false, text: 'footer.subscribing.ok', error: false });
    } catch (e) {
      setStatus({
        anim: false,
        text: 'footer.subscribing.failed',
        error: true,
      });
    }
  };

  return (
    <SubscribeBox>
      <SubscribeBoxTitle>Подпишитесь на нашу рассылку</SubscribeBoxTitle>
      <SubscribeBoxForm>
        <SubscribeBoxInput
          name="email"
          label="footer.email"
          value={email}
          onChange={(_, v) => setEmail(v)}
          placeholder="ivanov_ivan@pochta.ru"
        />
        <SubscribeBoxSubmitButton onClick={onSubmitEmail}>
          Отправить
        </SubscribeBoxSubmitButton>
        {status.anim && <Spinner />}
        {status.text !== '' && <p>{status.text}</p>}
      </SubscribeBoxForm>
      <SubscribeBoxPrivacyText>
        Оставляя заявку вы соглашаетесть с нашей{' '}
        <SubscribeBoxPrivacyLink href="">
          политикой конфиденциальности
        </SubscribeBoxPrivacyLink>
      </SubscribeBoxPrivacyText>
    </SubscribeBox>
  );
}
