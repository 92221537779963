import React from 'react';
import PropTypes from 'prop-types';
import { Section, List, Item, Headline, Image, Content } from './styled';
import { Container } from '../styled';
import list from './data';

export default function EasyToUse() {
  return (
    <Section>
      <Container>
        <Headline>Легко использовать!</Headline>
        <List>
          {list.map((item) => (
            <Item key={item.id}>
              <Blob
                id={item.id}
                coordinates={item.coordinates}
                animate={item.animate}
              />
              <Image src={item.icon} />
              <Content dangerouslySetInnerHTML={{ __html: item.title }} />
            </Item>
          ))}
        </List>
      </Container>
    </Section>
  );
}

function Blob({ id, animate, coordinates: { x, y } }) {
  return (
    <div className="item-blob">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        width="100%"
      >
        <path id="blob" fill="#84B8D0">
          <animate
            attributeName="d"
            dur="20200ms"
            repeatCount="indefinite"
            values={animate}
          />
        </path>
        <text x={x} y={y} className="blob-text">
          {id}
        </text>
      </svg>
    </div>
  );
}

Blob.propTypes = {
  id: PropTypes.string.isRequired,
  animate: PropTypes.string.isRequired,
  coordinates: PropTypes.objectOf(
    PropTypes.exact({
      x: PropTypes.string.isRequired,
      y: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};
