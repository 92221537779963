import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { media } from '../../../ui/common';

export const Section = styled.section`
  padding: 42px 0;
  overflow: hidden;
  ${media.phone`
     padding: 56px 0;
  `}
  ${media.medium`
     padding: 125px 0 250px;
  `}
`;

export const Heading = styled.h1`
  font-family: 'Craftwork Grotesk';
  margin: 0 0 50px;
  color: #3e3e3e;
  font-size: 32px;
  line-height: 1.2;
  letter-spacing: 0.025rem;
  ${media.phone`
     font-size: 56px;
     line-height: 1.2;
     letter-spacing: 0.05rem;
     margin-bottom: 42px;
  `}
  ${media.medium`
    margin-bottom: 46px;
  `}
  ${media.laptop`
    font-size: 70px;
    letter-spacing: 0.01rem;
    margin-bottom: 46px;
  `}
`;

export const List = styled.ul`
  font-family: 'HelveticaNeueCyr', sans-serif;
  padding: 0;
  margin: 0 -8px 40px;
  font-size: 20px;
  counter-reset: list;
  list-style: none;
  ${media.phone`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  `}
  ${media.medium`
    max-width: 900px;
  `}
`;
export const Item = styled.li`
  padding: 0 8px;
  box-sizing: border-box;
  margin: 0 0 30px;
  line-height: 1.57rem;

  &:before {
    font-family: 'Craftwork Grotesk';
    counter-increment: list;
    content: counter(list);
    display: block;
    width: 53px;
    height: 53px;
    color: white;
    background: #ff3b65;
    border-radius: 12px;
    font-weight: 600;
    font-size: 76px;
    line-height: 52px;
    text-align: center;
    margin-bottom: 16px;
  }
  ${media.phone`
    width: 50%;
  `}
  ${media.medium`
    width: 33.33%;
  `}
`;
export const Button = styled.a`
  font-family: 'HelveticaNeueCyr', sans-serif;
  background: #ff3b65;
  box-shadow: 0 12px 30px rgba(255, 59, 101, 0.25);
  border-radius: 13px;
  font-weight: 700;
  font-size: 16px;
  color: white;
  height: 58px;
  line-height: 54px;
  white-space: nowrap;
  border: none;
  text-transform: uppercase;
  padding: 0 38px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 200ms ease;
  display: inline-block;

  &:hover {
    color: #000;
    background: transparent;
    border: 2px solid #ff3b65;
    filter: drop-shadow(0px 12px 30px rgba(255, 59, 101, 0.25));
  }
`;

export const RevertEmoji = styled.span`
  display: inline-block;
  font-size: 25px;
  transform: scale(-1, 1) translate(-6px, 4px);
  margin-left: 4px;
`;

export const Image = styled(GatsbyImage)`
  height: 435px;
  ${media.phone`
    height: 562px;
  `}
  ${media.medium`
    height: 850px;
  `}
`;

export const ImageContainer = styled.div`
  z-index: -1;
  position: relative;
  margin: 0 -50px -112px;
  ${media.phone`
    margin: 0 -30px -222px;
    transform: translate(0, 0);
  `}
  ${media.medium`
    margin: 0;
    width: 100%;
    right: -298px;
    bottom: -248px;
    position: absolute;
  `}
`;
