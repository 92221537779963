import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import {
  Wrapper,
  CardListWrapper,
  ProductCardWrapper,
  NotesCardWrapper,
  Heading,
  FullImg,
  Info,
  PriceWrapper,
  PriceTitle,
  StyledInfoButton,
  Price,
  StyledBuyButton,
  BuyButtonText,
  BuyButtonDiscount,
  BuyButtonDiscountText,
  TooltipWrapper,
  TooltipList,
  SpecsTitle,
  SpecsList,
} from './styled';
import {
  isEvaChill,
  isEvaLightPlus,
  isEvaSmart,
} from '../../../utils/slug-checks';
import { getItemFullImage, getItemName } from '../../../utils/api/items';
import useItemsData from '../../Products/Info/use-items-data';
import useBasket from '../../../hooks/use-basket';
import useSlider from '../hooks';
import ColorPicker from './ColorPicker';

function Tooltip() {
  return (
    <TooltipWrapper>
      <TooltipList>
        <li>Скопируй код из СМС</li>
        <li>
          Добавь устройство в корзину, кликнув кнопку &quot;Купить со
          скидкой&quot;
        </li>
        <li>Примени код в корзине, чтобы получить скидку 20%</li>
      </TooltipList>
    </TooltipWrapper>
  );
}

function BuyButton({ onClick }) {
  return (
    <StyledBuyButton type="button" onClick={onClick}>
      <BuyButtonText>КУПИТЬ СО СКИДКОЙ</BuyButtonText>
      <BuyButtonDiscount>
        <StaticImage
          alt="fire"
          layout="fixed"
          width={18}
          src="../../../images/samokat/fire.png"
          placeholder="none"
          quality={95}
        />
        <BuyButtonDiscountText>20%</BuyButtonDiscountText>
      </BuyButtonDiscount>
    </StyledBuyButton>
  );
}

BuyButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function InfoButton({ onClick }) {
  return (
    <StyledInfoButton type="button" onClick={onClick}>
      <svg
        width="20"
        height="20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 18a8 8 0 1 1 8-8 8.01 8.01 0 0 1-8 8Zm0-8.5a1 1 0 0 0-1 1v3a1 1 0 1 0 2 0v-3a1 1 0 0 0-1-1Zm0-4A1.25 1.25 0 1 0 10 8a1.25 1.25 0 0 0 0-2.5Z"
          fill="#fff"
        />
      </svg>
    </StyledInfoButton>
  );
}
InfoButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

function Card({ title, info, price, toggleTooltip, data }) {
  const { addItem } = useBasket();
  const { value, allItems } = data;
  const name = getItemName(value);
  const { data: itemsData, setColor, setPlug, toDevice } = useItemsData(name);
  const selectedColor = itemsData.color;
  const imgBig = getItemFullImage(value, selectedColor);
  const item =
    itemsData.selectedIndex === -1
      ? null
      : itemsData.available[itemsData.selectedIndex];
  useEffect(() => {
    if (data.hasPlug) setPlug('EU/UK');
  }, [data.hasPlug]);
  useEffect(() => {
    toDevice();
  }, [data]);
  const addItemToCart = () => {
    const itemId = item.deviceId;
    const variantItem = allItems.find((val) => val.id === itemId);

    if (variantItem) {
      addItem(variantItem, true);
    }
  };

  return (
    <ProductCardWrapper>
      <Heading>{title}</Heading>
      <Info>{info}</Info>
      <FullImg alt={title} src={imgBig} />
      <PriceTitle>Цена без скидки</PriceTitle>

      {itemsData.color && itemsData.colors && (
        <ColorPicker
          prefix={`${title}-color-picker`}
          colorList={itemsData.colors}
          activeColor={itemsData.color}
          onChange={setColor}
        />
      )}

      <PriceWrapper>
        <Price>{price}</Price>
        <InfoButton onClick={toggleTooltip} />
        <Tooltip />
      </PriceWrapper>
      <BuyButton onClick={addItemToCart} />
    </ProductCardWrapper>
  );
}
Card.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  toggleTooltip: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

function ProductCards({
  allItems,
  evaChill,
  evaLight,
  evaSmart,
  swiperElementRef,
}) {
  return (
    <div className="swiper-container" ref={swiperElementRef}>
      <div className="swiper-wrapper">
        {evaChill && (
          <div className="swiper-slide">
            <Card
              title="evaCHILL"
              info="Компактный и легкий"
              price="5 990 р."
              data={{ value: evaChill, hasPlug: false, allItems }}
            />
          </div>
        )}
        {evaLight && (
          <div className="swiper-slide">
            <Card
              title="evaLIGHT+"
              info="Яркий и удобный"
              price="9 990 р."
              data={{ value: evaLight, hasPlug: true, allItems }}
            />
          </div>
        )}
        {evaSmart && (
          <div className="swiper-slide">
            <Card
              title="evaSMART"
              info="Умный и стильный"
              price="14 990 р."
              data={{ value: evaSmart, hasPlug: true, allItems }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
ProductCards.propTypes = {
  allItems: PropTypes.array.isRequired,
  evaChill: PropTypes.object.isRequired,
  evaLight: PropTypes.object.isRequired,
  evaSmart: PropTypes.object.isRequired,
  swiperElementRef: PropTypes.any.isRequired,
};

function SpecsCards({ evaChill, evaLight, evaSmart, swiperElementRef }) {
  return (
    <div className="swiper-container" ref={swiperElementRef}>
      <div className="swiper-wrapper">
        {evaChill && (
          <div className="swiper-slide">
            <NotesCardWrapper>
              <SpecsList>
                <li>Супер-портативный;</li>
                <li>Удобная ручка;</li>
                <li>Одна кнопка управления;</li>
                <li>Монохромная подсветка;</li>
                <li>Четыре скоростных режима;</li>
                <li>Супер-Картридж внутри.</li>
              </SpecsList>
            </NotesCardWrapper>
          </div>
        )}
        {evaLight && (
          <div className="swiper-slide">
            <NotesCardWrapper>
              <SpecsList>
                <li>Цифровой дисплей;</li>
                <li>RGB подсветка;</li>
                <li>Десять скоростных режимов;</li>
                <li>Колесо управления;</li>
                <li>Таймер;</li>
                <li>Съемный бак для воды;</li>
                <li>Картридж внутри.</li>
              </SpecsList>
            </NotesCardWrapper>
          </div>
        )}
        {evaSmart && (
          <div className="swiper-slide">
            <NotesCardWrapper>
              <SpecsList>
                <li>Сенсорный дисплей;</li>
                <li>Динамическая RGB подсветка;</li>
                <li>Управление через мобильное приложение;</li>
                <li>Интеграция с Алисой и системами умного дома Google;</li>
                <li>Гибкая настройка скоростных режимов;</li>
                <li>Встроенный to-do-list (расписание охлаждения);</li>
                <li>Съемный бак для воды;</li>
                <li>Картридж внутри.</li>
              </SpecsList>
            </NotesCardWrapper>
          </div>
        )}
      </div>
    </div>
  );
}
SpecsCards.propTypes = {
  evaChill: PropTypes.object.isRequired,
  evaLight: PropTypes.object.isRequired,
  evaSmart: PropTypes.object.isRequired,
  swiperElementRef: PropTypes.any.isRequired,
};

export default function Shop() {
  const allItems = useSelector((state) => state.items.list);
  const evaChill = allItems.find(isEvaChill);
  const evaLight = allItems.find(isEvaLightPlus);
  const evaSmart = allItems.find(isEvaSmart);
  const { elementRef: productSwiperElementRef, swiperRef: productSwiperRef } =
    useSlider();
  const { elementRef: specsSwiperElementRef, swiperRef: specsSwiperRef } =
    useSlider();
  useEffect(() => {
    if (!productSwiperRef.current || !specsSwiperRef.current) return;

    productSwiperRef.current.controller.control = specsSwiperRef.current;
    specsSwiperRef.current.controller.control = productSwiperRef.current;
  }, [productSwiperRef.current, specsSwiperRef.current]);

  return (
    <Wrapper section id="samokat-shop">
      <CardListWrapper>
        <ProductCards
          swiperElementRef={productSwiperElementRef}
          allItems={allItems}
          evaChill={evaChill}
          evaLight={evaLight}
          evaSmart={evaSmart}
        />

        <SpecsTitle>ОТЛИЧИЯ МОДЕЛЕЙ</SpecsTitle>
        <SpecsCards
          swiperElementRef={specsSwiperElementRef}
          evaChill={evaChill}
          evaLight={evaLight}
          evaSmart={evaSmart}
        />
      </CardListWrapper>
    </Wrapper>
  );
}
