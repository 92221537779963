// Based on https://codepen.io/jh3y/pen/poEvKxo

import styled, { css, keyframes } from 'styled-components';
import waveSvg from '../../../images/samokat/wave.svg';

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: ${(props) => (props.heightInherit ? 'inherit' : `${props.height}vh`)};
  width: 100%;
  ${(props) =>
    props.$isInverted &&
    css`
      transform: scale(-1, -1);
    `}
`;

const wave = keyframes`
  to {
    transform: translate(-50%, 0);
  }
`;
export const Content = styled.div`
  animation: ${wave} ${(props) => props.$speed}s infinite linear;
  background-image: url(${waveSvg});
  background-size: 50% 100%;
  height: ${(props) => (props.heightInherit ? 'inherit' : `${props.height}vh`)};
  left: 0;
  opacity: ${(props) => props.$opacity};
  position: absolute;
  right: 0;
  width: ${(props) => (props.widthInehrit ? 'inherit' : `${props.width}vw`)};
  will-change: transform;
`;
