import styled from 'styled-components';
import { media } from '../../../ui/common';
import Blob from '../Blob';

export const Section = styled.section`
  font-family: 'HelveticaNeueCyr';
  overflow: hidden;
  position: relative;
  padding-bottom: 60px;
  ${media.phone`
    padding-top: 30px;
  `}
  ${media.medium`
    padding-bottom: 160px;
  `}
`;

export const Row = styled.div`
  ${media.medium`
    display: flex;
    flex-direction: row-reverse;
  `}
`;
export const Content = styled.div`
  margin-top: -32px;
  font-size: 20px;
  line-height: 1.25;
  ${media.phone`
    margin-top: 28px;
  `}
  ${media.medium`
    width: 50%;
  `}
`;
export const Headline = styled.h2`
  font-family: 'Craftwork Grotesk';
  font-size: 24px;
  margin: 0;
  ${media.phone`
    font-size: 30px;
  `}
  ${media.medium`
    font-weight: 700;
    font-size: 42px;
  `}
`;
export const Block = styled.div`
  color: white;
  padding: 24px 24px;
  box-sizing: border-box;
  background: #84b8d0;
  box-shadow: 0 21px 34px -9px rgba(0, 0, 0, 0.25);
  border: 1px solid #ffffff;
  border-radius: 16px;
  &:nth-of-type(2) {
    margin-top: 12px;
    position: relative;
    z-index: -1;
  }
  ${media.tablet`
   &:nth-of-type(1) {
      transform: translate(-36px, 0);
    }
    &:nth-of-type(2) {
      
      transform: translate(58px, 0);
    }
  `}
  ${media.medium`
    &:nth-of-type(1) {
      transform: translate(0, 0);
    }
    &:nth-of-type(2) {
      z-index: 1;
      transform: translate(60%,-30px);
    }
  `}
`;

export const Text = styled.div`
  p {
    margin: 24px 0;
  }
  ${media.phone`
    margin-bottom: 240px;
  `}
  ${media.tablet`
    margin-bottom: 227px;
  `}
  ${media.medium`
    margin-bottom: 24px;
  `}
`;
export const ImageWrap = styled.div`
  position: relative;
  color: #84b8d0;
  ${media.medium`
    width: 50%;
  `}
`;
export const Image = styled.div`
  display: none;
  max-width: 345px;
  ${media.phone`
    display: block;
  `}
  ${media.medium`
    max-width: 490px;
    transform: translate(100px,70px);
  `}
`;

export const ImageBlob = styled(Blob)`
  color: #84b8d0;
  width: 380px;
  transform: translate(-30%, 0);
  z-index: -1;
  ${media.phone`
    position: absolute;
    transform: translate(0, 0);
    top: 16px;
    left: 180px
  `}
  ${media.medium`
    width: 600px;
    top: 60px;
    left: 260px;
  `}
`;
export const CloudImage = styled.div`
  position: absolute;
  max-width: 330px;
  top: 100px;
  z-index: 1;
  padding-right: 24px;
  ${media.phone`
    z-index: -1;
    top: auto;
    bottom: 215px;
    max-width: 490px;
  `}
  ${media.medium`
    bottom: 0px;
  `}
`;
