import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import { Wrapper, Button } from './styled';
import { BuyButtonDiscount, BuyButtonDiscountText } from '../Shop/styled';

export default function Popup() {
  return (
    <Wrapper>
      <Button>
        КУПИТЬ С ПРОМОКОДОМ
        <BuyButtonDiscount>
          <StaticImage
            alt="fire"
            layout="fixed"
            width={18}
            src="../../../images/samokat/fire.png"
            placeholder="none"
            quality={95}
          />
          <BuyButtonDiscountText>20%</BuyButtonDiscountText>
        </BuyButtonDiscount>{' '}
      </Button>
    </Wrapper>
  );
}
