import React from 'react';
import { useIntl } from 'react-intl';
import {
  Footer as Layout,
  FooterContainer,
  Copyright,
  CompanyInfo,
  Title,
  LinkBox,
} from './styled';
import Subscribe from './Subscribe';

export default function Footer() {
  const intl = useIntl();

  return (
    <Layout>
      <FooterContainer>
        <Subscribe />
        <div>
          <Title>Контакты</Title>
          <CompanyInfo>
            ООО &quot;КБ ЕВА&quot; <br />
            ИНН 7813616303 <br />
            КПП 784201001 <br />
            ОГРН 1187847209989 <br />
            191119, Черняховского 39, лит. А, <br />
            помещение 1-H, к. 7 Санкт-Петербург, <br />
            Россия тел. +7 (812) 565-34-42
          </CompanyInfo>
          <LinkBox href="mailto:mail@evapolar.com">mail@evapolar.com</LinkBox>
          <LinkBox href="https://support.evapolar.com">
            {intl.formatMessage({ id: 'footer.help' })}
          </LinkBox>
        </div>
      </FooterContainer>
      <Copyright>{`© Evapolar, 2016–${new Date().getFullYear()}`}</Copyright>
    </Layout>
  );
}
