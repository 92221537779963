import React from 'react';
import Wave from '../Wave';
import {
  Section,
  Body,
  Row,
  Content,
  Heading,
  Text,
  Image,
  ImageWrap,
  DeviceBlob,
  BlobGroup,
  TinyBlob,
  SmallBlob,
  AsideBlob,
  Button,
} from './styled';
import { Container } from '../styled';
import img from '../../../images/samokat/samokat_promo_device.png';
import { animate1, animate2, animate3 } from '../Blob/animate';
import ScrollEvaBreezeContext from '../contexts';
import getOffsetTop from '../../../utils/get-offset-top';

export default function Promo() {
  const evaBreezeTouchUpRef = React.useRef(null);
  const { evaBreezePositionData, setEvaBreezePositionData } = React.useContext(
    ScrollEvaBreezeContext
  );

  const imageWrapStyles = {
    transform: evaBreezePositionData?.translateY,
  };

  React.useEffect(() => {
    if (evaBreezeTouchUpRef.current) {
      const imageWrapOffsetTop = getOffsetTop(evaBreezeTouchUpRef.current);

      setEvaBreezePositionData({
        ...evaBreezePositionData,
        touchUpY: imageWrapOffsetTop,
      });
    }
  }, [evaBreezeTouchUpRef.current]);

  return (
    <Section id="promo">
      <Wave />
      <Body>
        <Container>
          <BlobGroup>
            <TinyBlob animate={animate1} />
            <SmallBlob animate={animate2} />
          </BlobGroup>
          <Row>
            <ImageWrap ref={evaBreezeTouchUpRef} style={imageWrapStyles}>
              <DeviceBlob />
              <AsideBlob animate={animate3} />
              <Image alt="img" src={img} />
            </ImageWrap>
            <Content>
              <Heading>Evapolar спасёт от жары, где бы ты ни был</Heading>
              <Text>
                Компактный мини-кондиционер, который охлаждает, увлажняет и
                очищает воздух вокруг тебя.
              </Text>
              <Button href="#samokat-shop">ОХЛАДИТЬСЯ</Button>
            </Content>
          </Row>
        </Container>
      </Body>
      <Wave isInverted />
    </Section>
  );
}
