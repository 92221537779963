/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';
import { media } from '../../ui/common';

export const Container = styled.div`
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
  max-width: 452px;
  position: relative;

  ${media.phone`
    max-width: 600px;
  `}
  ${media.medium`
    padding: 0 30px;
    max-width: 1200px;
  `} //  ${media.tablet`
  //   background: #ff00001c;
  // `}
  // ${media.medium`
  //   background: #ff00001c;
  // `}
  // ${media.laptop`
  //   background: #39bf8d24;
  // `}
  // ${media.desktop`
  //   background: #4a89dc3d;
  // `}
`;
