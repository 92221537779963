import styled from 'styled-components';
import { media } from '../../../ui/common';

export const Section = styled.div`
  padding: 60px 0 60px;
  ${media.medium`
    padding: 160px 0 80px;
  `}
`;
export const FeaturesContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 0 20px 30px;
  margin: 0 auto;
  max-width: 1164px;
`;

export const SwiperWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  margin: 0 auto;
`;

export const Feature = styled.div`
  max-width: 375px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 10px 20px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    max-width: 400px;
    padding: 0;
  }

  img {
    width: 100%;
    margin: 0 auto;
  }
`;
export const FeatureImg = styled.div``;

export const FeatureTitle = styled.p`
  text-align: center;
  margin: 0;
  margin-top: auto;
  padding-top: 20px;
  font-weight: 400;
  font-size: 24px;
  line-height: 124%;
  color: #3e3e3e;
`;
