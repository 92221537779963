import styled from 'styled-components';
import { media } from '../../../ui/common';

export const Section = styled.section`
  padding: 60px 0;
`;

export const List = styled.ul`
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 10px 0;
  font-family: 'Craftwork Grotesk';
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
  color: #3e3e3e;
  opacity: 0;
  transition: 300ms all;

  &.--visible {
    opacity: 1;
  }

  ${media.phone`
    font-size: 30px;
    letter-spacing: 0.025rem;
  `}
  ${media.medium`
    font-weight: 700;
    letter-spacing: normal;
  `}
  ${media.laptop`
    font-size: 42px;
  `}
`;

export const Icon = styled.span`
  background: #fff;
  box-shadow: 0 17px 20px -7px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  margin-right: 24px;
  padding: 16px;

  img {
    display: block;
  }
`;
