import { useRef, useEffect } from 'react';
import Swiper from 'swiper';

const useSlider = () => {
  const elementRef = useRef(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    swiperRef.current = new Swiper(elementRef.current, {
      grabCursor: true,
      slidesPerView: 1,
      watchOverflow: true,
      breakpoints: {
        375: {
          slidesPerView: 1.1,
        },
        520: {
          slidesPerView: 1.5,
        },
        700: {
          slidesPerView: 1.8,
        },
        900: {
          slidesPerView: 2.2,
        },
        1050: {
          slidesPerView: 2.5,
        },
        1200: {
          slidesPerView: 3,
        },
      },
      observer: true,
      observeParents: true,
    });

    return () => {
      swiperRef.current.destroy();
    };
  }, []);

  return { elementRef, swiperRef };
};

export default useSlider;
