import React from 'react';
import PropTypes from 'prop-types';
import { Item, Icon } from './styled';
import getOffsetTop from '../../../utils/get-offset-top';

export default function SpecLi(props) {
  const { iconSrc, title } = props;
  const [isVisible, setVisible] = React.useState(null);
  const currentRef = React.useRef(null);

  const correlateToScroll = React.useCallback(() => {
    if (!currentRef.current) return;
    const liOffsetTop = getOffsetTop(currentRef.current);
    const isInViewPort =
      liOffsetTop < window.scrollY + window.innerHeight / 1.3;

    if (isInViewPort) {
      setVisible(true);
    }
  }, [currentRef.current]);

  React.useEffect(() => {
    window.addEventListener('scroll', correlateToScroll);

    if (isVisible) {
      window.removeEventListener('scroll', correlateToScroll);
    }
  }, [isVisible]);

  return (
    <Item ref={currentRef} className={`${isVisible && '--visible'}`}>
      <Icon>
        <img src={iconSrc} alt="" />
      </Icon>
      {title}
    </Item>
  );
}

SpecLi.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
