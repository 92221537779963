import styled from 'styled-components';
import { media } from '../../../ui/common';

export const Section = styled.section`
  padding: 120px 0;
  overflow: hidden;
  .blob-text {
    font-family: 'Craftwork Grotesk';
    color: white;
    fill: white;
    text-align: center;
    font-weight: 700;
    font-size: 360px;
  }
  ${media.medium`
    padding-bottom: 32px;
  `}
`;

export const List = styled.ul`
  counter-reset: list;
  list-style: none;
  display: block;
  padding: 0;
  margin: 76px 0 0;
  ${media.phone`
    max-width: 568px;
    margin: 103px auto 100px;
  `}
`;

export const Headline = styled.h2`
  font-family: 'Craftwork Grotesk';
  font-size: 24px;
  text-align: center;
  ${media.phone`
    font-weight: 700;
    font-size: 42px;
  `}
`;
export const Item = styled.li`
  font-family: 'HelveticaNeueCyr', sans-serif;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 16px;
  color: #3e3e3e;
  position: relative;

  .item-blob {
    position: absolute;
    width: 280px;
    height: 280px;
    z-index: -1;
    ${media.phone`
      width: 400px;
      height: 400px;
    `}
  }

  &:nth-child(1) {
    margin-bottom: 126px;
    .item-blob {
      bottom: -76px;
      left: -102px;
      ${media.phone`
        bottom: -132px;
        left: 66px;
      `}
    }
    img {
      transform: rotate(15deg) translate(-5px, -22px);
      ${media.phone`
        transform: rotate(15deg) translate(28px,-8px);
        &+ div {
          transform: translateY(-70px);
        }
      `}
    }
    ${media.phone`
      margin-bottom: 103px;
    `}
  }
  &:nth-child(2) {
    margin-bottom: 134px;
    flex-direction: row-reverse;
    .item-blob {
      top: -90px;
      right: -68px;
      ${media.phone`
        top: 34px;
        right: -196px;
      `}
      ${media.medium`
        top: 18px;
        right: -224px;
      `}
    }
    img {
      transform: translate(0, 0px);
      ${media.phone`
        transform: translate(-11px,0px);
        &+ div {
          padding-right: 30px;
        }
      `}
    }
    ${media.phone`
      margin-bottom: 88px;
    `}
    ${media.medium`
      transform: translate(16px, 0px);
    `}
  }
  &:nth-child(3) {
    margin-bottom: 156px;
    .item-blob {
      top: -60px;
      left: -110px;
      ${media.phone`
        top: -164px;
        left: -260px;
      `}
      ${media.medium`
        top: -130px;
        left: -338px;
      `}
    }
    img {
      transform: rotate(-15deg) translate(8px, -25px);
      ${media.phone`
        transform: rotate(-15deg) translate(-25px,8px);
      `}
    }

    p:not(:first-child) {
      ${media.phone`
        margin-top: 30px;
      `}
    }
    ${media.phone`
      margin-bottom: 148px;
    `}
    ${media.medium`
      transform: translate(40px, 0px);
    `}
  }
  &:nth-child(4) {
    flex-direction: row-reverse;
    .item-blob {
      top: -124px;
      right: -48px;
      ${media.phone`
        top: 100px;
        right: -162px;
      `}
      ${media.medium`
        top: -62px;
        right: -258px;
      `}
    }
    img {
      transform: rotate(15deg) translate(-6px, -27px);
      ${media.phone`
        transform: rotate(15deg) translate(-10px,2px);
      `}
    }
  }
  ${media.phone`
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  `}
  ${media.medium`
  `}
  ${media.laptop`
  `}
`;

export const Content = styled.div`
  margin: 12px auto 0;
  max-width: 230px;
  line-height: 1.25;
  p {
    margin: 0;
  }
  p:not(:first-child) {
    margin-top: 20px;
  }
  ${media.phone`
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    max-width: 270px;
  `}
`;

export const Image = styled.img`
  background: #fff;
  box-shadow: 0 25px 38px rgba(0, 0, 0, 0.25);
  min-width: 200px;
  width: 200px;
  height: 200px;
  border-radius: 32px;
  overflow: hidden;
  ${media.phone`
    min-width: 267px;  
    width: 267px;  
    height: 267px;
  `}
`;
