import React from 'react';
import PropTypes from 'prop-types';

import { StyledA } from './styled';

export default function Anchor({ children, className }) {
  return (
    <StyledA className={className} href="#samokat-shop">
      {children}
    </StyledA>
  );
}

Anchor.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Anchor.defaultProps = {
  className: undefined,
};
