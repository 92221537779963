import React from 'react';
import {
  Title,
  WaveWrapper,
  Img,
  ImgBox,
  Section,
  HeartsIconWrap,
  Footer,
  FooterContainer,
} from './styled';
import Image from '../../../images/samokat/child-comfort.png';
import Wave from '../Wave';
import HeartsIcon from '../../../images/samokat/HeartsIcon';

const RULES_LINK =
  'https://s3.amazonaws.com/pg.evapolar.com/ru/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0_%D0%A1%D0%B0%D0%BC%D0%BE%D0%BA%D0%B0%D1%82_Evapolar.pdf';

export default function LiveWithComfort() {
  return (
    <Section>
      <ImgBox>
        <Title>Живи с комфортом</Title>
        <Img src={Image} alt="live-with-comfort" />
        <HeartsIconWrap>
          <HeartsIcon />
        </HeartsIconWrap>
      </ImgBox>
      <WaveWrapper>
        <Wave heightInherit widthInherit />
      </WaveWrapper>
      <Footer>
        <FooterContainer>
          <p>Полные правила Акции доступны по ссылке</p>
          <a href={RULES_LINK} target="_blank" rel="noreferrer">
            правила
          </a>
        </FooterContainer>
      </Footer>
    </Section>
  );
}
