import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Anchor from '../Anchor';
import Logo from '../../Logo';

export const StyledAppBar = styled(AppBar)`
  display: block;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(54px);
  box-shadow: none;
  z-index: 2;
  padding: 0 100px;

  /* prettier-ignore */
  @supports not ((backdrop-filter: none)) {
    background: #232323;
  }

  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const StyledToolbar = styled(Toolbar)`
  min-height: 80px;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;
  max-width: 1140px;

  @media (max-width: 768px) {
    max-width: 568px;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const BigImageWrapper = styled.div`
  flex: 0 0 134px;

  @media (max-width: 420px) {
    display: none;
  }
`;

export const SmallImageWrapper = styled.div`
  display: none;

  @media (max-width: 420px) {
    display: block;
    flex: 0 0 94px;
  }
`;

export const StyledLink = styled.a`
  @media (max-width: 420px) {
    & .gatsby-image-wrapper {
      width: 94px !important;
    }
  }
`;

export const StyledLogo = styled(Logo)`
  flex: 0 0 108px;
  width: 108px;
  height: 23px;

  @media (max-width: 420px) {
    display: block;
    flex: 0 0 76px;
    width: 76px;
    height: 16px;
  }
`;

export const Delimiter = styled.div`
  flex: 0 0 2px;
  height: 46px;
  background: #d9d9d9;
  margin: 0 18px;

  @media (max-width: 420px) {
    margin: 0 12px;
  }
`;

export const RightSide = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: 12px;
`;

export const BasketButton = styled.button`
  width: 52px;
  height: 52px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;

  & svg {
    fill: #84b8d0;
  }

  &:hover {
    border: 2px solid #fff;
    background-color: transparent;
    transition: all 200ms ease;
    transition-property: border, background-color;

    & svg {
      fill: #fff;
      transition: fill 200ms ease;
    }
  }
`;

export const StyledAnchor = styled(Anchor)`
  display: flex;
  align-items: center;
  white-space: nowrap;
  span {
    margin-left: 14px;
  }
  @media (max-width: 869px) {
    display: none;
  }
`;
