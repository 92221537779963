import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import {
  Section,
  Headline,
  Content,
  ImageWrap,
  Row,
  Text,
  Block,
  Image,
  ImageBlob,
  CloudImage,
} from './styled';
import { Container } from '../styled';
import { animate4 } from '../Blob/animate';

export default function EvaBreeze() {
  const { tech, cloud } = useStaticQuery(graphql`
    {
      tech: file(relativePath: { eq: "samokat/samokat_tech.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
      cloud: file(relativePath: { eq: "samokat/samokat_cloud.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
    }
  `);

  return (
    <Section>
      <CloudImage>
        <GatsbyImage image={getImage(cloud)} />
      </CloudImage>
      <Container>
        <Row>
          <ImageWrap>
            <Image>
              <GatsbyImage image={getImage(tech)} />
            </Image>
            <ImageBlob animate={animate4} />
          </ImageWrap>
          <Content>
            <Headline>Инновационная технология evaBREEZE</Headline>
            <Text>
              <p>
                Привет! Мы — Evapolar, и мы верим, что персональный климат — это
                важная составляющая комфорта. Именно поэтому в 2015 году мы
                запустили бренд персональных кондиционеров.
              </p>
              <p>
                Разработанный нами уникальный материал evaBREEZE обеспечивает
                более эффективное охлаждение в компактном размере.
              </p>
            </Text>
            <div>
              <Block>
                Его нанопористая структура гарантирует мощный охлаждающий эффект
              </Block>
              <Block>
                А базальтовые волокна не способствуют размножению бактерий и
                плесени
              </Block>
            </div>
          </Content>
        </Row>
      </Container>
    </Section>
  );
}
