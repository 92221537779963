import React from 'react';
import { Container } from '../styled';
import { Section, List } from './styled';
import list from './data';
import ScrollEvaBreezeContext from '../contexts';
import getOffsetTop from '../../../utils/get-offset-top';
import SpecLi from './li-item';

export default function Specs() {
  const evaBreezeTouchDownRef = React.useRef(null);
  const { evaBreezePositionData, setEvaBreezePositionData } = React.useContext(
    ScrollEvaBreezeContext
  );

  React.useEffect(() => {
    if (evaBreezeTouchDownRef.current) {
      const listOffsetTop = getOffsetTop(evaBreezeTouchDownRef.current);

      setEvaBreezePositionData({
        ...evaBreezePositionData,
        touchDownY: listOffsetTop,
      });
    }
  }, [evaBreezeTouchDownRef.current]);

  return (
    <Section>
      <Container>
        <List ref={evaBreezeTouchDownRef}>
          {list.map((el) => (
            <SpecLi key={el.key} iconSrc={el.icon} title={el.title} />
          ))}
        </List>
      </Container>
    </Section>
  );
}
