import React from 'react';
import {
  Section,
  Feature,
  FeatureTitle,
  FeaturesContainer,
  SwiperWrapper,
} from './styled';
import blobedFeature1 from '../../../images/samokat/blobedFeature.png';
import blobedFeature2 from '../../../images/samokat/blobedFeature2.png';
import blobedFeature3 from '../../../images/samokat/blobedFeature3.png';
import useSlider from '../hooks';

export default function Features() {
  const { elementRef } = useSlider();

  return (
    <Section>
      <FeaturesContainer className="swiper-container" ref={elementRef}>
        <SwiperWrapper className="swiper-wrapper">
          <div className="swiper-slide">
            <Feature>
              <img src={blobedFeature1} alt="feature1" />
              <FeatureTitle>Удобно переносить</FeatureTitle>
            </Feature>
          </div>
          <div className="swiper-slide">
            <Feature>
              <img src={blobedFeature2} alt="feature2" />
              <FeatureTitle>Легко использовать</FeatureTitle>
            </Feature>
          </div>
          <div className="swiper-slide">
            <Feature>
              <img src={blobedFeature3} alt="feature3" />
              <FeatureTitle>Комфортно работать</FeatureTitle>
            </Feature>
          </div>
        </SwiperWrapper>
      </FeaturesContainer>
    </Section>
  );
}
