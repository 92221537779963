/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';

export const StyledA = styled.a`
  font-family: 'HelveticaNeueCyr', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.24;
  text-align: center;
  color: #3e3e3e;
  padding: 16px 32px;
  cursor: pointer;
  border: 0;
  background-color: #fff;
  box-shadow: 0 12px 30px rgba(255, 255, 255, 0.25);
  border-radius: 13px;
  transition: all 200ms ease;
  transition-property: outline, background-color, color;
  &:hover {
    background-color: transparent;
    outline: 2px solid #fff;
    color: #fff;
  }
`;
