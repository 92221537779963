import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage, withArtDirection } from 'gatsby-plugin-image';
import { Container } from '../styled';
import {
  Section,
  Heading,
  List,
  Item,
  Button,
  Image,
  ImageContainer,
  RevertEmoji,
} from './styled';

export default function Hero() {
  const { heroSm, heroMd, heroLg } = useStaticQuery(graphql`
    {
      heroSm: file(relativePath: { eq: "samokat/samokat_hero_sm.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 375
            quality: 100
            layout: FULL_WIDTH
            placeholder: TRACED_SVG
          )
        }
      }
      heroMd: file(relativePath: { eq: "samokat/samokat_hero_md.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 768
            quality: 100
            layout: FULL_WIDTH
            placeholder: TRACED_SVG
          )
        }
      }
      heroLg: file(relativePath: { eq: "samokat/samokat_hero_lg.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: TRACED_SVG)
        }
      }
    }
  `);
  return (
    <Section>
      <Container>
        <ImageContainer>
          <Image
            image={withArtDirection(getImage(heroSm), [
              { media: '(min-width: 960px)', image: getImage(heroLg) },
              { media: '(min-width: 600px)', image: getImage(heroMd) },
            ])}
          />
        </ImageContainer>
        <Heading>
          Охладись вместе
          <br />
          с&nbsp;Самокатом и&nbsp;Evapolar
        </Heading>
        <List>
          <Item>
            Заказывай прохладительные
            <br />
            напитки и мороженое
            <br />в приложении “Cамокат” на
            <br />
            чек от 250р
          </Item>
          <Item>
            Получай гарантированный
            <br />
            промокод и шанс выиграть
            <br />
            одно из устройств Evapolar
          </Item>
          <Item>
            Ищи свое имя среди
            <br />
            победителей в день
            <br />
            завершения Акции
          </Item>
        </List>
        <Button href="#promo">
          узнать больше
          <RevertEmoji>🚴</RevertEmoji>
        </Button>
      </Container>
    </Section>
  );
}
